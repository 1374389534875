<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <b-overlay :show="showOverlay" rounded="sm">
      <b-card style="max-width: 100%; width: 100%" class="mb-2">
        <div
          v-if="readOnly"
          class="alert alert-light alert-elevate"
          role="alert"
        >
          <div class="alert-text">
            <p>
              {{ $t("PARAMETERS.ALERT") }}
            </p>
          </div>
        </div>
        <b-tabs content-class="mt-3" pills>
          <b-tab :title="$t('COMMON.MAIN')" active>
            <b-card-body>
              <b-form-group
                id="field-name"
                label-cols-lg="2"
                :label="$t('PARAMETERS.NAME')"
                label-for="field-name"
              >
                <b-form-input
                  v-bind:disabled="readOnly"
                  id="field-name-input"
                  v-model="dto.name"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="field-description"
                label-cols-lg="2"
                :label="$t('PARAMETERS.DESCRIPTION')"
                label-for="field-description-input"
              >
                <b-form-input
                  v-bind:disabled="readOnly"
                  id="field-description-input"
                  v-model="dto.description"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="field-code"
                label-cols-lg="2"
                :label="$t('PARAMETERS.CODE')"
                label-for="field-code-input"
              >
                <b-form-input
                  id="field-code-input"
                  v-model="dto.code"
                  v-bind:disabled="readOnly"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                v-if="user.grants.includes('SYSTEM:ADMIN')"
                id="field-global"
                label-cols-lg="2"
                :label="$t('PARAMETERS.GLOBAL')"
                label-for="field-global-input"
              >
                <b-form-checkbox
                  v-bind:disabled="readOnly"
                  id="checkbox-approved-input"
                  v-model="dto.global"
                  name="checkbox-global"
                  class="pt-2"
                  switch
                  size="lg"
                >
                </b-form-checkbox>
              </b-form-group>

              <b-form-group
                id="field-attachment"
                label-cols-lg="2"
                :label="$t('PARAMETERS.ATTACHMENT')"
                label-for="field-attachment-input"
              >
                <b-form-checkbox
                  v-bind:disabled="readOnly"
                  id="checkbox-attachment-input"
                  v-model="dto.attachment"
                  name="checkbox-attachment"
                  class="pt-2"
                  switch
                  size="lg"
                >
                </b-form-checkbox>
              </b-form-group>

              <b-form-group
                id="field-circle"
                label-cols-lg="2"
                :label="$t('PARAMETERS.CIRCLE')"
                label-for="field-circle-input"
              >
                <b-form-checkbox
                  v-bind:disabled="readOnly"
                  id="checkbox-circle-input"
                  v-model="dto.circle"
                  name="checkbox-circle"
                  class="pt-2"
                  switch
                  size="lg"
                >
                </b-form-checkbox>
              </b-form-group>

              <b-form-group
                label-cols-lg="2"
                :label="$t('PARAMETERS.CONCENTRATION')"
                label-for="field-circle-input"
              >
                <b-form-checkbox
                  v-bind:disabled="readOnly"
                  v-model="dto.concentration"
                  class="pt-2"
                  switch
                  size="lg"
                >
                </b-form-checkbox>
              </b-form-group>

              <b-form-group
                id="field-unit"
                label-cols-lg="2"
                :label="$t('PARAMETERS.UNIT')"
                label-for="field-unit"
              >
                <b-form-select
                  v-model="dto.unitId"
                  :options="unitTypes"
                  v-bind:disabled="readOnly"
                >
                  <template v-slot:first>
                    <option :value="null" disabled>
                      {{ $t("PARAMETERS.SELECT_UNIT") }}
                    </option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-card-body>
          </b-tab>
        </b-tabs>

        <div style="float: right">
          <b-button
            v-if="!readOnly"
            variant="success"
            v-on:click="onSaveClicked"
            >{{ $t("COMMON.SAVE") }}</b-button
          >
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { API_REQUEST } from "@/core/services/store/api.module";
//import Common from "../../common";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapState } from "vuex";
import ApiService from "@/core/services/api.service";

export default {
  name: "parameter",
  components: {},
  data() {
    return {
      save: {
        resource: "/api/parameter/save",
        requestType: "POST",
        requestParams: {},
      },

      dto: {
        id: null,
        name: null,
        description: null,
        code: null,
        global: false,
        teamName: null,
        teamId: ApiService.teamData.value,
        unitId: null,
        unitName: null,
        attachment: false,
        circle: false,
        concentration: false,
      },

      getUnitTypes: {
        resource: "/platform/api/dictionary/com.gracelogic.nlg.core.model.Unit",
        requestType: "GET",
        requestParams: {},
      },
      unitTypes: [],

      selectedTeamId: null,
      showOverlay: true,

      createNewStr: this.$t("PARAMETERS.CREATE_NEW_PARAMETER"),
      editStr: this.$t("PARAMETERS.EDIT_PARAMETER"),
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    readOnly() {
      return (
        !this.user.grants.includes("SYSTEM:ADMIN") &&
        this.dto.id != null &&
        this.dto.global == true
      );
    },
  },

  mounted() {
    let title = !this.$route.params.id ? this.createNewStr : this.editStr;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.PARAMETERS"), route: "/parameters" },
      { title: title },
    ]);
  },

  created: async function () {
    if (this.$route.params.id != null) {
      await this.$store
        .dispatch(API_REQUEST, {
          resource: "/api/parameter/" + this.$route.params.id,
          requestType: "GET",
        })
        .then((response) => (this.dto = response))
        .catch(this.onError);
    }

    this.showOverlay = false;

    //    if (this.dto.id != null) {
    //      this.$refs.teamTypeahead.$data.inputValue = this.dto.teamName;
    //    }

    this.loadUnitTypes();
  },

  methods: {
    loadUnitTypes: function () {
      this.$store
        .dispatch(API_REQUEST, this.getUnitTypes)
        .then((response) => {
          for (const value of response) {
            this.unitTypes.push({ value: value.id, text: value.nameLocalized });
          }
        })
        .catch(this.onError);
    },

    onTeamSelected: function (value) {
      this.dto.teamId = value.id;
    },

    onSaveClicked: function () {
      this.showOverlay = true;
      const request = this.save;
      request.dto = this.dto;

      this.$store
        .dispatch(API_REQUEST, request)
        .then((response) => {
          this.dto.id = response.id;

          this.$bvToast.toast(this.$t("COMMON.SAVED_SUCCESSFULLY"), {
            title: this.$t("COMMON.SUCCESS"),
            variant: "success",
            autoHideDelay: 5000,
          });
        })
        .catch(this.onError)
        .finally(() => {
          this.showOverlay = false;
        });
    },

    onError: function (response) {
      if (response && response.config) response = response.data;

      this.$bvToast.toast(
        response && response.message
          ? response.message
          : this.$t("COMMON.UNKNOWN_ERROR"),
        {
          title: this.$t("COMMON.ERROR"),
          variant: "danger",
          autoHideDelay: 5000,
        }
      );
    },
  },
};
</script>
<style>
.hidden {
  display: none;
}
.form-group {
  margin-bottom: 1rem;
}
</style>
